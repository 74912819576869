/**
 * Basic type of analytics event. These are limited to 500 in total by Google
 * Analytics. Specific event is recorded in metadata. See
 * [AnalyticsMetadataKeys].
 */
export enum AnalyticsEventType {
    search = "search",
    log = "log",
    error = "error",
    recoverableError = "recoverable_error",
    test = "test",
    userInteraction = "user_interaction",
    viewLocation = "view_location",
    viewBookingOption = "view_booking_option",
    beginCheckout = "begin_checkout",
    purchase = "purchase",
    fetchedData = "fetched_data",
    fetchedUrlParameters = "fetched_url_parameters",
    viewBookingOverview = "view_booking_overview",
    clickListItem = "click_list_item",
}
